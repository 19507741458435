const showFaq = () => {

    const faqItems = document.querySelectorAll('.faq__item'),
          faqItemText = document.querySelectorAll('.faq__text');

    faqItems.forEach((item, i) => {
        item.addEventListener('click', (e) => {
            if (faqItemText[i].classList.contains('faq__text_hide')) {
                faqItemText[i].style.maxHeight = faqItemText[i].scrollHeight + 'px';
                faqItemText[i].classList.remove('faq__text_hide');
            } else {
                faqItemText[i].style.maxHeight = 0;
                faqItemText[i].classList.add('faq__text_hide');
            }
        });
    });

};

export default showFaq;