const gallerySlider = () => {

  $(document).ready(function(){
    $('.gallery').slick({
      slidesToShow: 3,
      infinite: true,
      variableWidth: true,
      arrows: false,
      centerMode: true,
      initialSlide: 1,
      swipeToSlide: true
        
    });
  }); 

};

export default gallerySlider;

